  <div class="row">
    <div class="col-md-12">
      <div class="dataTables_filter d-none">
        <label>
          Search:
          <input type="search" (input)="onSearchChange($event)" class="form-control">
        </label>
      </div>      <div>
        <h2>Complete list of All orders</h2>
        <table datatable [dtOptions]="dtOptions"
               class="table table-hover table-bordered display pl-2 pr-2 py-0 w-100"
               style="cursor: pointer;">
        </table>
      </div>
    </div>
  </div>
