import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ApiService} from '../services/api.service';
import {OrdersService} from '../services/orders.service';
import {OrderList} from '../models/orders.model';
import * as moment from 'moment';
import {TableStateService} from '../services/table-state.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  orders: OrderList[];
  noData = true;
  searchValue = '';

  public pageTitle = 'Order Management';

  constructor(private router: Router,
              private service: OrdersService,
              private tableService: TableStateService,
              private ngZone: NgZone) { }

  ngOnInit() {
    /*
    this.tableService.currentSearchValue
      .subscribe({
        next: data => {
          this.searchValue = data;
        }
      });
     */
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      /*
      stateSave: true,
      stateDuration: 60 * 60 * 24, // Store state for 24 hours (in seconds)
      search: {
        search: this.searchValue // Apply the saved search value
      },
      initComplete: (settings, json) => {
        // When table loads, set search box value
        $('.dataTables_filter input').val(this.searchValue);
      },
       */
      ajax: (dataTablesParameters: any, callback) => {
        this.ngZone.run(() => {
          this.service.getOrdersTable(
            dataTablesParameters)
            .subscribe(resp => {
              if (resp.hasOwnProperty('orderList')) {
                this.orders = resp.orderList;
                if (this.orders.length > 0) {
                  this.noData = false;
                } else {
                  this.noData = true;
                }
              } else {
                resp['orderList'] = [];
              }

              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: resp.orderList
              });
            });
        });
      },
      order: [[1, 'desc']],
      columns: [{
        title: 'ID',
        data: 'id'
      }, {
        title: 'Order Date',
        data: 'orderDate'
      }, {
        title: 'Customer',
        data: 'name'
      }, {
        title: 'Amount',
        data: 'amount',
        className: 'text-right',
        render: $.fn.dataTable.render.number( ',', '.', 2, '$ ', '' )
      }],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        $('td', row).unbind('click');
        $('td', row).bind('click', () => {
          self.someClickHandler(data);
        });
        const ordernumeric = data['orderDate'];
        const orderDate = moment(ordernumeric);
        $('td:eq(1)', row).text(orderDate.format('LLL'));
        return row;
      }
    };
  }

  someClickHandler(info: any): void {
    const orderId = info.id;
    this.router.navigateByUrl('order-detail/' + orderId);
    /*
    this.selectedDonor = {identity: info.identity};
    const dState: DonorState = {donor: this.selectedDonor, enhanced: info};
    this.store.dispatch(new donorActions.SetSelectedDonorAction(dState));
    this.router.navigateByUrl('/app/donor-details');
    */
  }

  // Save search value when it changes
  onSearchChange(event: any): void {
    const searchValue = event.target.value;
    this.tableService.updateSearchValue(searchValue);
    this.searchValue = searchValue;
  }

  logout() {
    this.router.navigateByUrl('');
  }
}
