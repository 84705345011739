import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableStateService {
  private searchValueSource = new BehaviorSubject<string>('');
  currentSearchValue = this.searchValueSource.asObservable();

  constructor() {
    // Load saved value from storage on service initialization
    const savedValue = localStorage.getItem('tableSearchValue') || '';
    this.searchValueSource.next(savedValue);
  }

  updateSearchValue(value: string): void {
    localStorage.setItem('tableSearchValue', value);
    this.searchValueSource.next(value);
  }
}
