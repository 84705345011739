import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {ValidationData} from '../shared/validationMessages.component';
import {SmsService} from '../services/sms.service';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';
import {MatRadioButton, MatRadioChange} from '@angular/material/radio';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit {
  smsForm: FormGroup;
  sending = false;
  disabled = true;
  minDate = moment();
  maxDate = this.minDate.add(7, 'days');
  showSpinners = true;
  showSeconds = false;
  enableMeridian = true;
  errorMessage = '';

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('lf', {static: true}) myForm: NgForm;

  validationData: ValidationData = {
    'messageBody': [
      {type: 'required', message: 'Please enter a message body for this text message'},
      {type: 'maxLength', message: 'Messages are limited to 320 characters.'}
    ]
  };

  constructor(private smsService: SmsService,
              private formBuilder: FormBuilder,
              private ngZone: NgZone) {
    this.smsForm = this.formBuilder.group({
      messageBody: ['', [Validators.required, Validators.maxLength(320)]],
      sendAt: ['now'],
      topic: ['nationalAlerts'],
      dateControl: [{value: '', disabled: true}]
    });

  }

  ngOnInit(): void {
  }

  doSend() {
    if (!this.smsForm.valid) {
      return;
    }
    const topicName = this.smsForm.controls.topic.value;
    const bodyMessage = (topicName === 'nationalAlerts') ? '2025 National: ' + this.smsForm.controls.messageBody.value :
        '2025 National Juniors: ' + this.smsForm.controls.messageBody.value;

    if (this.smsForm.controls.sendAt.value === 'atTime') {
      this.sending = true;
      const atTime = this.smsForm.controls.dateControl.value;
      const momentInTime = moment(atTime);
      if (momentInTime.isBetween(moment(), moment().add(7, 'days'))) {
        this.errorMessage = '';
        const atTimeString = moment(atTime).toISOString();
        console.log(atTimeString);
        this.smsService.sendAMessageAtLaterTime(topicName, bodyMessage, atTime)
          .subscribe((res) => {
            this.ngZone.run(() => {
              this.sending = false;
              if (res.hasOwnProperty('status')) {
                alert('Message Sent');
                this.smsForm.reset();
                this.myForm.resetForm();
                this.smsForm.controls.sendAt.setValue('now');
                this.smsForm.controls.dateControl.disable();
              } else {
                alert('Unable to send the message.  Please try again');
              }
            });
          },
            (err) => {
              alert('Unable to send the message.  Please try again');
            });
      } else {
        // notify about why
        this.errorMessage = 'The date/time selected can only be between now and up to 7 days in the future';
      }
    } else {
      this.sending = true;
      this.smsService.sendAMessage(topicName, bodyMessage)
        .subscribe((res) => {
          this.ngZone.run(() => {
            this.sending = false;
            if (res.hasOwnProperty('status')) {
              alert('Message Sent');
              this.smsForm.reset();
              this.myForm.resetForm();
              this.smsForm.controls.sendAt.setValue('now');
              this.smsForm.controls.dateControl.disable();
            } else {
              alert('Unable to send the message.  Please try again');
            }
          });
        },
          (err) => {
            alert('Unable to send the message.  Please try again');
          });
    }
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  changeRadio(mrChange: MatRadioChange) {
    if (mrChange.value === 'now') {
      this.smsForm.controls.dateControl.disable();
    } else {
      this.smsForm.controls.dateControl.enable();
    }
  }
}
